import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
const AutoplaySlider = withAutoplay(AwesomeSlider);


/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.post
    const leadershipPage = post.tags[0].slug === 'our-leadership'
    const servicesPage = post.tags[0].slug === 'our-services'
    const carousel = data.carousel.edges.filter(({ node }) => (
        "tags" in node && node.tags[0].slug === post.slug + "-carousel" 
    ))

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                { 
                    carousel.length > 0 ? 

                        <div className="site-banner">
                            <AutoplaySlider
                                play={true}
                                cancelOnInteraction={false} // should stop playing on user interaction
                                interval={6000}
                            >
                                {  carousel.map(({ node, i }) => (
                                    <div key={ i } data-src={ node.feature_image }>{ node.feature_image }</div>
                                ))}
                            </AutoplaySlider>
                        </div> 
                    :
                    servicesPage && post.feature_image && 

                     <div className="page-hero-image" style={{
                            backgroundImage: `url(${post.feature_image})` ,
                    }}></div>          
                }

                <div className="container">
                    <article className={'content ' + post.tags[0].slug }>
                        { !servicesPage && post.feature_image ?
                            <figure className="post-feature-image">
                                <img src={ post.feature_image } alt={ post.title } />
                            </figure> : null }
                        <section className="post-full-content">
                            <h1 className="content-title">{post.title}</h1>
                            { leadershipPage ? <h2> { post.excerpt } </h2> : '' }

                            {/* The main post content */ }
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        post: ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
        carousel: allGhostPost(filter: {tags: {elemMatch: {slug: {regex: "/carousel/"}}}}) {
            edges {
              node {
                id
                slug
                tags {
                  id
                  slug
                }
                feature_image
              }
            }
          }
    }
`
